<template>
    <div class="pickup-choice-modal">
        <div class="pickup-choice-content">
            <div class="pickup-choice-line">
                <el-select
                    :placeholder="$t('product_page.orders.pickup_date')"
                    v-model="pickup.date"
                    effect="dark"
                >
                    <el-option
                    v-for="item in pickupDate"
                    :key="item"
                    :label="item"
                    :value="item"
                    />
                </el-select>
            </div>

            <div class="pickup-choice-line">
                <div class="pickup-start">
                    <el-select
                        :placeholder="$t('product_page.orders.pickup_start')"
                        v-model="pickup.start"
                        effect="dark"
                    >
                        <el-option
                        v-for="item in getLists.pickup_start"
                        :key="item"
                        :label="item"
                        :value="item"
                        />
                    </el-select>
                </div>
                <div class="pickup-end">
                    <el-select
                        :placeholder="$t('product_page.orders.pickup_end')"
                        v-model="pickup.end"
                        effect="dark"
                        :disabled="!pickup.start"
                    >
                        <el-option
                        v-for="item in returnPickupEnd"
                        :key="item"
                        :label="item"
                        :value="item"
                        />
                    </el-select>
                </div>
            </div>
        </div>

        <div class="pickup-choice-actions">
            <button class="generic-btn">
                {{ $t('commons.confirm') }}
            </button>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import m from 'moment'
import { findIndex } from 'lodash'

 export default {
    emits: ['close'],
    data() {
      return {
        pickup: {
            date: null,
            start: null,
            end: null
        },
        indexEndMax: 0
      }
    },
    watch: {
        'pickup.start': function() {
            const findIndexStart = findIndex(this.getLists.pickup_start, (o) => {
                return o === this.pickup.start
            })

            const findIndexEnd = findIndex(this.getLists.pickup_end, (o) => {
                return o === this.pickup.end
            })

            if (this.pickup.start && findIndexStart !== -1) {
                this.indexEndMax = findIndexStart - 4;
                
                if (findIndexStart >= 2 && findIndexEnd !== -1) {
                    this.pickup.end = this.getLists.pickup_end[findIndexStart - 4];
                }
            }
        },
        'pickup.end': function() {
            const findIndexStart = findIndex(this.getLists.pickup_start, (o) => {
                return o === this.pickup.start
            })

            const findIndexEnd = findIndex(this.getLists.pickup_end, (o) => {
                return o === this.pickup.end
            })

            if (findIndexStart > -1 && findIndexEnd > -1) {
                if ((findIndexEnd - findIndexStart) < -4) {
                    this.pickup.end = null;
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            getLists: 'getLists',
        }),
        pickupDate() {
            let businessDays = [];
            let currentDate = m();

            while (businessDays.length < 2) {
                currentDate.add(1, 'days');
                let dayOfWeek = currentDate.isoWeekday();
                if (dayOfWeek !== 6 && dayOfWeek !== 7) {
                    businessDays.push(currentDate.format('DD/MM/YYYY'));
                }
            }

            return businessDays;
        },
        returnPickupEnd() {
            return this.getLists.pickup_end.slice(this.indexEndMax);
        }
    },
    methods: {},
    mounted() {}
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.pickup-choice-content {
    
    .pickup-choice-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .pickup-start, .pickup-end {
            width: 48%;
        }
    }
}

.pickup-choice-actions {
    display: flex;
    justify-content: center;
    width: 100%;
    
    button {
        width: 200px;
    }
}

@media screen and (max-width: 500px) {

}
</style>