<template>
  <div class="dialog">
    <div class="sell-order">
      <div class="sell-order-bottle" :class="{ removePadding: !firstImage }">
        <div class="order-title-description-bottle">
          <img
            v-if="firstImage"
            :alt="getProduct.title"
            :src="firstImage"
            height="58"
            width="58"
          />
          <img
            v-else
            :src="require('@/assets/svg/v2/bottle.svg')"
            alt="Product not found image"
            class="empty-bottle-picture"
            height="58"
            width="58"
          />
          <div>
            <h2>{{ getProduct.title }}</h2>
            <p>{{ getProduct.ticker }}</p>
          </div>
        </div>
        
        <el-popover placement="left" width="300" trigger="click">
          <template #reference>
            <div>
              <img src="@/assets/svg/v2/infos.svg" alt="Informations" width="23" />
            </div>
          </template>
          <div class="explain-content-order">
            <firstTimeCreateOrder readOnly type="sell" />
          </div>
        </el-popover>
      </div>

      <form enctype="multipart/form-data">
        <div class="form-input-bloc-header">
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_price') }}</h3>
            <el-input
              v-model="sellForm.price"
              :class="{ errorInput: sellFormRegex.price }"
              class="sell-order-input"
              placeholder="50-30000"
            />
          </div>
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_quantity') }}</h3>
            <el-input
              v-model="sellForm.quantity"
              :class="{ errorInput: sellFormRegex.quantity }"
              class="sell-order-input"
              max="10"
              min="1"
              placeholder="1"
              type="number"
              disabled
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.weight_unit') }}</h3>

            <el-select
              v-model="sellForm.weight_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_weight"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.sell_weight') }} ({{
                sellForm.weight_unit
              }})
            </h3>
            <el-input
              v-model="sellForm.weight"
              :class="{ errorInput: sellFormRegex.length }"
              class="sell-order-input"
              max="20"
              min="1"
              type="number"
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.size_unit') }}</h3>
            <el-select
              v-model="sellForm.size_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_dimensions"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.length') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.packageDimensions.length"
              :class="{ errorInput: sellFormRegex.length }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.width') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.packageDimensions.width"
              :class="{ errorInput: sellFormRegex.width }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.height') }} ({{ sellForm.size_unit }})
            </h3>
            <el-input
              v-model="sellForm.packageDimensions.height"
              :class="{ errorInput: sellFormRegex.height }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
        </div>

        <div class="form-input-bloc">
          <h3>{{ $t('product_page.orders.sell_pictures') }}</h3>

          <div class="file-zone">
            <div>
              <label
                for="input-file"
                id="drop-area"
                @dragover.prevent
                @drop="files($event)"
              >
                <input
                  ref="file"
                  :multiple="true"
                  accept="image/jpg, image/jpeg, image/png"
                  class="custom-file-input"
                  name="document"
                  type="file"
                  id="input-file"
                  @change="files"
                />
                <img
                  src="@/assets/svg/v2/portfolio/import.svg"
                  alt="File Icon"
                />
                <div class="input-text">
                  <p>
                    <span>{{ $t('product_page.orders.load_a_file') }}</span>
                    {{ $t('product_page.orders.or_drag_drop') }}
                  </p>
                  <p class="text-grey">{{ $t('product_page.orders.file_format_allowed') }}</p>
                </div>
              </label>
            </div>
          </div>

          <div v-if="selectedFiles.length > 0">
            <div class="image-previews">
              <div
                v-for="(file, index) in selectedFiles"
                :key="index"
                class="image-preview"
                @mouseenter="showDeleteButton(index)"
                @mouseleave="hideDeleteButton(index)"
              >
                <img
                  :src="file.preview"
                  alt="Selected Image"
                  class="image-preview"
                />

                <button class="delete-button" @click="removeFile(index)">
                  <img
                    id="delete"
                    :src="require(`@/assets/svg/v2/orders/trash.svg`)"
                    alt="Delete"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <span v-if="sellFormRegex.file" class="error" style="margin: 10px 0"
          >{{ $t('product_page.orders.max_min_pictures') }}</span
        >
        <div class="sell-form-options">
          <h4 class="options-title">{{ $t('product_page.orders.sell_options') }}</h4>
          <div class="sell-end-date-bloc">

            <div v-if="getUserDetails.identity.account_type === 0" class="options-bloc">
              <div>
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('product_page.orders.anonymous_description')"
                  placement="top"
                >
                  <h4 class="anonymous-bloc-title-tooltip">
                    <img alt="Anonymous transaction" src="@/assets/svg/v2/orders/anonymous_transaction.svg" />
                    {{ $t('product_page.orders.anonymous_transaction') }}
                  </h4>
                </el-tooltip>
              </div>

              <el-switch
                v-model="sellForm.anonymous"
                class="switch"
                style="
                  --el-switch-on-color: #2667ff;
                  --el-switch-off-color: #555555;
                "
              />
            </div>

            <!-- <div class="options-bloc">
              <div>
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('product_page.orders.pickup_description')"
                  placement="top"
                >
                  <h4 class="anonymous-bloc-title-tooltip">
                    <img alt="Anonymous transaction" src="@/assets/svg/v2/orders/pickup.svg" />
                    {{ $t('product_page.orders.pickup') }}
                  </h4>
                </el-tooltip>
              </div>

              <el-switch
                v-model="sellForm.pickup"
                class="switch"
                style="
                  --el-switch-on-color: #2667ff;
                  --el-switch-off-color: #555555;
                "
              />
            </div> -->

            <div class="sell-end-date-inputs">
              <div class="sell-end-date-actions">
                <div class="sell-end-date-select">
                  <img alt="End date" src="@/assets/svg/v2/settings/hour.svg" />
                  <h4>{{ $t('product_page.orders.sell_end_date') }}</h4>
                </div>
                <el-switch
                  v-model="sellForm.endDate"
                  class="switch"
                  style="
                    --el-switch-on-color: #2667ff;
                    --el-switch-off-color: #555555;
                  "
                />
              </div>
              <div v-if="sellForm.endDate" class="sell-select-end-date">
                <el-date-picker
                  v-model="sellForm.date"
                  :class="{ errorInput: sellFormRegex.dateBeforeNow }"
                  :disabled-date="disabledDate"
                  class="generic-border"
                  format="DD/MM/YYYY"
                  placeholder="Choose a day"
                  type="date"
                  value-format="YYYY-MM-DD"
                />

                <el-input
                  v-model="sellForm.time"
                  :class="{
                    errorInput:
                      sellFormRegex.countdown || sellFormRegex.dateBeforeNow,
                  }"
                  class="sell-order-input-small"
                  placeholder="12:00"
                  value-format="HH:mm"
                  maxlength="5"
                  prefix-icon="el-icon-time"
                />
              </div>
              <span
                v-if="sellFormRegex.dateBeforeNow"
                class="error"
                style="margin: 10px 0"
                >{{ $t('product_page.orders.add_min_one_hour') }}</span
              >
            </div>
          </div>
        </div>
      </form>

      <button
        :disabled="isLoading"
        v-loading="isLoading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
        class="create-order-btn generic-btn"
        @click.prevent="createSellOrder()"
      >
        {{ $t('product_page.orders.sell_create_order') }}
      </button>

    </div>

    <CommonModal
        v-if="pickupChoiceDialog"
        :modal-title="$t('product_page.orders.pickup')"
        size="small"
        @close="closePickupChoice()"
      >
        <pickupChoice @close="closePickupChoice()" />
    </CommonModal>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'
import CommonModal from '@/components/commons/modal.vue'
import pickupChoice from '@/components/modules/dialogs/pickupChoice.vue'
import firstTimeCreateOrder from './firstTimeCreateOrder.vue'
import m from 'moment'
import { find } from 'lodash'

export default {
  props: {
    order: {
      type: Object,
      required: false,
    },
  },
  components: {
    CommonModal,
    pickupChoice,
    firstTimeCreateOrder
  },
  data() {
    return {
      selectedFiles: [],
      isLoading: false,
      pickupChoiceDialog: false,
      sellForm: {
        price: null,
        weight_unit: 'KGS',
        size_unit: 'CM',
        quantity: 1,
        weight: null,
        packageDimensions: {
          length: null,
          width: null,
          height: null,
        },
        endDate: false,
        date: null,
        time: null,
        anonymous: false,
        pickup: false
      },
      sellFormRegex: {
        price: false,
        quantity: false,
        endDate: false,
        date: false,
        time: false,
        dateBeforeNow: false,
        length: false,
        width: false,
        height: false,
        file: false,
        weight: false,
        anonymous: false,
        pickup: false
      },
    }
  },
  mounted() {
    if (this.order) {
      this.sellForm.price = this.order.price
    }
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
      getLists: 'getLists',
      getUserDetails: 'getUserDetails',
    }),
    firstImage() {
      if (this.getProduct.files_attachments) {
        return this.getProduct.files_attachments[0]
      }
      return ''
    },
    sellDateFormat() {
      if (this.sellForm.date !== null && this.sellForm.time !== null) {
        return `${this.sellForm.date} ${this.sellForm.time}`
      } else {
        return m().add(1, 'years').format('YYYY-MM-DD HH:mm')
      }
    },
  },
  watch: {
    'sellForm.time': function() {
      if (this.sellForm.time) {
        let slashCount = 0
        for (var i = 0; i < this.sellForm.time; i++) {
          if (this.sellForm.time[i] === ':') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.sellForm.time = ''
        }

        if (this.sellForm.time.length === 2 && slashCount === 0) {
          this.sellForm.time += ':'
        }
      }
    },
    sellDateFormat() {
      const now = m().add(1, 'hours').format('YYYY-MM-DD HH:mm')

      if (this.sellForm.date && this.sellForm.time) {
        const isBeforeNow = m(
          `${this.sellForm.date} ${this.sellForm.time}`
        ).isBefore(now)

        if (isBeforeNow) {
          this.sellForm.time = null
          this.sellForm.date = null
        } else {
          this.sellFormRegex.dateBeforeNow = false
        }
      }
    },
    'sellForm.pickup': function() {
      if (this.sellForm.pickup) {
        this.pickupChoiceDialog = true;
      }
    }
  },
  methods: {
    disabledDate(time) {
      const today = new Date()
      const threeMonthsFromNow = new Date(
        today.getFullYear(),
        today.getMonth() + 3,
        today.getDate()
      )
      return (
        time.getTime() < today.getTime() ||
        time.getTime() > threeMonthsFromNow.getTime()
      )
    },
    checkDuplicateFiles() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        for (let j = i + 1; j < this.selectedFiles.length; j++) {
          if (this.selectedFiles[i].preview === this.selectedFiles[j].preview) {
            return true
          }
        }
      }
      return false
    },

    getWeight(bottleSize, weightUnit) {
      const weightItem = this.getLists.minimum_weight.find(
        (item) => item.bottle_size === parseInt(bottleSize)
      )
      if (!weightItem) return null

      switch (weightUnit) {
        case 'KGS':
          return weightItem.weight / 1000
        case 'LBS':
          return weightItem.weight / 453.59237
        case 'OZS':
          return weightItem.weight * 0.035274
        default:
          return null
      }
    },
    ...mapActions({
      createOrderActions: 'createSellOrder',
    }),
    async createSellOrder() {
      const regex_price = /^[1-9][0-9]{0,5}$/
      const quantity_regex = /^[0-9]{1,3}$/
      //   const isbn_regex = /^[0-9a-zA-Z]{1,15}$/;
      const dimensions_regex = /^[1-9][0-9]{0,3}$/
      const hourRegex = /^(?:[0-9]{2}):(?:[0-9]{2})$/

      const payload = {
        isbn: this.getProduct.isbn,
        quantity: this.sellForm.quantity,
        price: this.sellForm.price,
        weight_unit: this.sellForm.weight_unit,
        size_unit: this.sellForm.size_unit,
        anonymous: this.sellForm.anonymous,
        weight: this.sellForm.weight,
        countdown: `${this.sellForm.date} ${this.sellForm.time}`,
        packageDimensions: this.sellForm.packageDimensions,
      }
      if (this.sellForm.endDate) {
        if (m(payload.countdown).isValid()) {
          this.sellFormRegex.countdown = false

          const now = m().toString()
          const countdownAddHour = m(payload.countdown, 'YYYY-MM-DD HH-mm').add(
            1,
            'h'
          )

          if (m(now).isBefore(countdownAddHour)) {
            this.sellFormRegex.dateBeforeNow = false
          } else {
            this.sellFormRegex.dateBeforeNow = true
          }
        } else {
          this.sellFormRegex.dateBeforeNow = true
        }
      } else {
        this.sellFormRegex.countdown = false
      }

      if (
        parseInt(this.sellForm.weight) <
        this.getWeight(this.getProduct.bottle_size, this.sellForm.weight_unit) *
          this.sellForm.quantity
      ) {
        this.sellFormRegex.weight = true
      } else {
        this.sellFormRegex.weight = false
      }

      if (
        regex_price.test(this.sellForm.price) &&
        parseInt(this.sellForm.price) >= 50 &&
        parseInt(this.sellForm.price) <= 30000
      ) {
        this.sellFormRegex.price = false
      } else {
        this.sellFormRegex.price = true
      }

      if (
        quantity_regex.test(this.sellForm.quantity) &&
        parseInt(this.sellForm.quantity) >= 1
      ) {
        this.sellFormRegex.quantity = false
      } else {
        this.sellFormRegex.quantity = true
      }

      if (this.sellForm.time) {
        if (hourRegex.test(this.sellForm.time)) {
          this.sellFormRegex.time = false
        } else {
          this.sellFormRegex.time = true
        }
      }

      if (!dimensions_regex.test(payload.packageDimensions.length)) {
        this.sellFormRegex.length = true
      } else {
        this.sellFormRegex.length = false
      }

      if (!dimensions_regex.test(payload.packageDimensions.width)) {
        this.sellFormRegex.width = true
      } else {
        this.sellFormRegex.width = false
      }

      if (!dimensions_regex.test(payload.packageDimensions.height)) {
        this.sellFormRegex.height = true
      } else {
        this.sellFormRegex.height = false
      }

      if (this.getProduct.bottle_size === 500 || this.getProduct.bottle_size === 700 || this.getProduct.bottle_size === 750) {
        if (payload.packageDimensions.length < 10 || payload.packageDimensions.width < 10 || payload.packageDimensions.height < 10) {
          this.sellFormRegex.length = true
          this.sellFormRegex.width = true
          this.sellFormRegex.height = true
        } else {
          this.sellFormRegex.length = false
          this.sellFormRegex.width = false
          this.sellFormRegex.height = false
        }
      }

      if (this.checkDuplicateFiles()) {
        this.sellFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.duplicate_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else if (
        this.selectedFiles.length < Number(this.getProduct.allowed_min_pictures) ||
        this.selectedFiles.length > 8
      ) {
        this.sellFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.max_min_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else {
        this.sellFormRegex.file = false
      }

      if (
        !this.sellFormRegex.weight &&
        !this.sellFormRegex.quantity &&
        !this.sellFormRegex.price &&
        !this.sellFormRegex.file &&
        !this.sellFormRegex.countdown &&
        !this.sellFormRegex.dateBeforeNow &&
        !this.sellFormRegex.length &&
        !this.sellFormRegex.width &&
        !this.sellFormRegex.height &&
        !this.sellFormRegex.time
      ) {
        const formData = new FormData()
        formData.append('isbn', payload.isbn)
        formData.append('quantity', payload.quantity)
        formData.append('full_size', payload.full_size)
        formData.append('price', payload.price)
        formData.append('weight', payload.weight)
        formData.append('weight_unit', payload.weight_unit)
        formData.append('size_unit', payload.size_unit)
        formData.append('anonymous', payload.anonymous)
        formData.append('bottle_size', this.getProduct.bottle_size)
        formData.append('package_dimensions', JSON.stringify(payload.packageDimensions))

        if (payload.countdown != 'null null') {
          formData.append('countdown', payload.countdown)
        }

        for (let fileCount = 0; fileCount < this.selectedFiles.length; fileCount++) {
          formData.append('files', this.selectedFiles[fileCount].file)
        }

        this.isLoading = true

        await this.createOrderActions(formData).then(() => {
            this.$store.commit('CREATE_ORDER_DIALOG', false)

            if (this.order) {
              this.$router.go(-1)
            }
          }).catch((error) => {
            if (error.response.status === 406) {
              this.$store.commit('CREATE_ORDER_DIALOG', false)
              this.$router.push('/register');
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    closeOrder() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    files(event) {
      if (event.dataTransfer) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
      }

      for (let fileCount = 0; fileCount < this.$refs.file.files.length; fileCount++) {
        if (this.$refs.file.files) {
          const ext = /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/
          const fileName = this.$refs.file.files[fileCount].name
          const fileSize = this.$refs.file.files[fileCount].size

          if (!ext.test(fileName) || fileSize > 7000000) {
            ElMessage({
              message: this.$t('product_page.orders.pictures_allowed'),
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const file = {
              file: this.$refs.file.files[fileCount],
              preview: URL.createObjectURL(this.$refs.file.files[fileCount]),
              name: this.$refs.file.files[fileCount].name,
            }

            const findSameName = find(this.selectedFiles, { name: file.name });

            if (!findSameName) {
              this.selectedFiles.push(file)
            } else {
              ElMessage({
              message: this.$t('product_page.orders.duplicate_pictures'),
              type: 'warning',
              grouping: true,
              duration: 4000,
            })
            }
          }
        }
      }
    },
    showDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: true,
      }
    },
    hideDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: false,
      }
    },
    removeFile(index) {
      event.preventDefault()
      this.selectedFiles.splice(index, 1)
    },
    closePickupChoice() {
      this.pickupChoiceDialog = false;
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.explain-content-order {
  max-height: 700px;
  overflow-y: scroll;
}

.explain-content-order::-webkit-scrollbar {
  width: 5px;
}

.explain-content-order::-webkit-scrollbar-track {
  background: $background;
}

.explain-content-order::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.explain-content-order::-webkit-scrollbar-thumb:hover {
  background-color: $grey40;
}

.options-bloc {
  display: flex;
}

.delete-button {
  display: block;
  margin-top: 5px;
  padding: 5px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.v-loading-spinner {
  border-radius: 52px;
}

.v-loading-spinner-inner {
  border-radius: 52px;
}

.delete-overlay img {
  width: 16px;
  height: 16px;
}

.input-line {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
}

.form-input-bloc-header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 65%;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centered {
  text-align: center;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .delete-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  &:hover {
    .delete-button {
      display: block;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.package-dimensions {
  display: flex;
}

.dialog {
  z-index: 5;
  position: relative;

  .sell-order {
    background: #333333;

    .sell-order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      border-radius: 20px 20px 0px 0px;
      border-radius: 20px 20px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(244, 63, 94, 0.16) 0%,
        rgba(244, 63, 94, 0) 100%
      );
      border-bottom: 1px solid $border;

      .sell-order-header-title {
        display: flex;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          background: #f43f5e;
          border-radius: 50%;
          margin-right: 14px;
        }
      }

      button {
        background: transparent;
        border-radius: 76px;
        border: 1px solid $border;
        background: #444;
        width: 38px;
        height: 38px;

        img {
          margin-top: 4px;
        }
      }
    }

    .sell-order-bottle {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-bottom: 15px;
      margin: 25px auto;
      margin-bottom: 10px;
      border-bottom: 1px solid $border;
      justify-content: space-between;

      .order-title-description-bottle {
        display: flex;
        align-items: center;

        div {
          margin-left: 10px;
        }
      }

      div {

        h2 {
          color: white;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          border: 1px solid $border;
          color: $grey40;
          margin-top: 12px;
          border-radius: 6px;
          padding: 2px 4px;
          max-width: 120px;
          text-align: center;
        }
      }
    }

    form {
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;

      .margin-block-form {
        margin-right: 10px;
      }

      .form-input-bloc,
      .form-input-bloc-flex {
        margin-top: 18px;

        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }

      .form-input-bloc-flex {
        display: flex;
        align-items: center;

        h3 {
          width: 110px;
        }
      }

      .package-dimensions {
        width: 100%;

        .form-input-bloc {
          width: 23%;
        }
      }

      .sell-form-options {
        margin-top: 5px;

        .options-title {
          color: white;
          margin: 10px auto;
        }

        h4 {
          color: $subtitle;
          font-size: 16px;
          font-weight: 600;
        }

        .sell-end-date-bloc {
          .sell-end-date-inputs {
            .sell-end-date-actions {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;

              div {
                display: flex;
                align-items: center;

                h4 {
                  color: $subtitle;
                  font-size: 16px;
                  font-weight: 500;
                  margin-left: 8px;
                }
              }
            }

            .sell-select-end-date {
              display: flex;
              justify-content: space-between;
              margin-top: 12px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .create-order-btn {
      margin-top: 51px;
      margin-bottom: 20px !important;
      width: 194px;
      margin: auto;
    }
  }
}

.sell-order-input {
  border-radius: 5px;
  border: 1px solid $border;
}

.options-bloc {
  width: 100%;
  margin-bottom: 3px;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;

    h4 {
      margin-left: 8px;
      font-weight: 500!important;
    }
  }
}

.anonymous-bloc-title-tooltip {
  display: flex;
  align-items: center;
  margin-left: 0!important;
  
  img {
    margin-right: 8px;
  }
}

.sell-order-input-small {
  border-radius: 5px;
  border: 1px solid $border;
  max-width: 30%;
}

.custom-file-input {
  width: 492px;
  height: 41px;
  margin-top: 13px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid $border;
  background: #444;
  color: $subtitle;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button:hover {
  background: #444;
}

.activeType {
  background: white !important;
  color: black !important;
}

@media screen and (max-width: 768px) {
  .explain-content-order {
    max-height: 450px;
    overflow-y: scroll;
  }

  .form-input-bloc-header {
    width: 100%;
    align-items: flex-start;
  }

  .image-preview {
    margin-right: 0;
    max-width: 80px;
  }

  .package-dimensions {
    flex-wrap: wrap;
    flex-direction: row;
    
    .form-input-bloc {
      width: 48%;
      margin-right: 4%;

      h3 {
        margin-bottom: 3px;
      }
    }

    .form-input-bloc:nth-child(2n) {
      margin-right: 0;
    }
  }

  .custom-file-input,
  input[type='file']::file-selector-button {
    width: 100%;
  }

  .dialog .sell-order-header {
    align-items: flex-start;

    button {
      width: 30px;
      height: 30px;

      img {
        margin-top: 2px;
      }
    }
  }

  .dialog .sell-order-bottle {
    align-items: flex-start;
    padding: 10px;

    div {
      margin-left: 0;
    }

    p {
      max-width: 100%;
    }
  }

  .sell-form-options .sell-end-date-inputs {
    align-items: flex-start;

    .sell-end-date-actions {
      align-items: flex-start;

      div {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .sell-select-end-date {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  .create-order-btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .form-input-bloc-header {
    width: 100%;
  }

  .dialog {
    .sell-order {
      .sell-order-bottle {
        flex-direction: row;
        padding: 5px;
        margin: -10px auto 10px auto;
      }

      form {
        padding-left: 0px;
        padding-right: 0px;
        .package-dimensions {
        .form-input-bloc {
          width: 100%;
          margin-top: 10px;
          h3 {
            font-size: 14px;
          
          }
      }
    }

      .create-order-btn {
        margin-top: 20px;
      }

      }
    }
  }


  .image-preview {
    img {
    max-width: 60px;
    padding-right: 10px;
    }
  }

  .dialog .sell-order {
    padding: 5px;
  }

  .dialog .sell-order-header {
    padding: 8px;
  }

  .sell-form-options .sell-end-date-inputs .sell-end-date-actions,
  .sell-form-options .sell-end-date-inputs .sell-select-end-date {
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .sell-order-bottle div h2 {
    font-size: 16px;
  }

  .sell-order-bottle div p {
    font-size: 14px;
  }

  .sell-form-options h4 {
    font-size: 14px;
  }

  .create-order-btn {
    margin-top: 20px;
  
  }

  .package-dimensions {
    flex-wrap: initial;
  }
  .package-dimensions .form-input-bloc {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 2%;
  }

  .package-dimensions .form-input-bloc:nth-child(2n) {
    margin-right: 0;
  }
}


</style>
